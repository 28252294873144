<template>
  <el-dialog
    append-to-body
    :title="title"
    :visible.sync="visible"
    width="80%"
    :show-close="true"
  >
    <div class="cont-wrap">
      <el-form
        ref="selectFrom"
        :model="queryParam"
        :inline="true"
        label-width="80px"
        label-position="left"
        class="cont-form"
      >
        <el-form-item label="结算单号">
          <el-input
            v-model.trim="queryParam.sheetCode"
            placeholder="请输入结算单号"
            maxlength="32"
            clearable
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="结算阶段">
          <el-select
            v-model="queryParam.sheetStage"
            multiple
            placeholder="请选择结算阶段"
            collapse-tags
            clearable
            filterable
          >
            <el-option
              v-for="item in dictionary.tradeSheetStage"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 32px">
          <el-button type="default" @click="handleReset">重置</el-button>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        stripe
        border
        height="500"
        :data="tableData"
        v-loading="dataLoading"
        highlight-current-row
        @current-change="handleSelectionChange"
        :header-cell-style="handleHeaderCellStyle"
        class="cont-list"
      >
        <el-table-column type="index" fixed="left" label="序号" width="60" />
        <el-table-column
          style="cursor: pointer"
          prop="sheetCode"
          fixed="left"
          label="结算单号"
          min-width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.sheetCode }}
          </template>
        </el-table-column>
        <el-table-column
          prop="tradeTime"
          label="单据时间"
          width="180"
          :formatter="fmtTradeTime"
        />
        <el-table-column
          prop="sellerMainName"
          label="供方名称"
          min-width="220"
          show-overflow-tooltip
        />
        <el-table-column
          prop="sellerMainCode"
          label="供方编码"
          min-width="160"
          show-overflow-tooltip
        />
        <el-table-column
          prop="buyerMainName"
          label="需方名称"
          min-width="220"
          show-overflow-tooltip
        />
        <el-table-column
          prop="buyerCode"
          label="需方编码"
          min-width="220"
          show-overflow-tooltip
        />
        <el-table-column
          prop="sheetSumAmount"
          label="结算金额"
          width="140"
          header-align="right"
          align="right"
          :formatter="fmtTradeAmount"
        />
        <el-table-column prop="receiveStatus" label="交票状态" width="160">
          <template slot-scope="scope">
            {{
              getDictLabel(
                dictionary.tradeReceiveStatus,
                scope.row.receiveStatus
              )
            }}（{{ scope.row.receivedCount }}/{{ scope.row.ticketCount }}）
          </template>
        </el-table-column>
        <el-table-column prop="sheetStage" label="结算阶段" width="100">
          <template slot-scope="scope">
            <span>
              {{
                getDictLabel(dictionary.tradeSheetStage, scope.row.sheetStage)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="auditStatus" label="稽核状态" width="100">
          <template slot-scope="scope">
            <span>
              {{
                getDictLabel(dictionary.tradeAuditStatus, scope.row.auditStatus)
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryParam.pageNo"
          :page-sizes="pageSizes"
          :page-size="queryParam.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="dataTotal"
        >
        </el-pagination>
      </div>
    </div>
    <div slot="footer" align="center">
      <el-button
        type="default"
        :loading="loading"
        @click="handleCancel"
        style="margin-right: 15px"
        >取消</el-button
      >
      <el-button
        type="primary"
        :loading="loading"
        @click="handleConfirm"
        style="margin-left: 15px"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { fmtCurrency } from "@/assets/js/format-util";
import {
  tableStyle,
  pagingMethods,
  dateOption,
  changeMethods,
} from "@/util/mixins";
import { reqPagingSettlement } from "@/service/coo/trade.js";
import {
  reqDoBindSettlementColInvoice,
} from "@/service/coo/trade-col-invoice.js";

export default {
  name: "SettlementSelectTrade",
  props: {
    cooRole: {
      type: String,
      default: "SS",
    },
    title: {
      type: String,
      default: "关联订单",
    },
  },
  mixins: [dateOption, tableStyle, pagingMethods, changeMethods],
  data() {
    return {
      // 数据字典
      dictionary: {
        tradeSheetStage: [], //结算阶段
        tradeAuditStatus: [], //稽核状态
        tradeReceiveStatus: [], //交票状态
      },

      // 筛选条件
      queryParam: {
        sheetCode: undefined,
        sheetStage: ["BI", "RI"],
        pageNo: 1,
        pageSize: 15,
      },

      // 表格数据
      dataTotal: 0,
      tableData: [],
      dataLoading: false,

      // 选中数据
      trade: undefined,
      invoices: [],

      // 执行操作
      visible: false,
      loading: false,
    };
  },
  async created() {
    await this.initDict();
    await this.handleQuery();
  },
  methods: {
    // 订单选择弹窗
    showDialog(invoices) {
      this.invoices = invoices;
      // console.log("invoices:", JSON.stringify(invoices));
      this.visible = true;
    },
    // 初始化字典
    async initDict() {
      const { data } = await this.batchGetDicts({
        types: [
          "trade_sheet_stage",
          "trade_audit_status",
          "trade_receive_status",
        ],
      });
      this.dictionary.tradeSheetStage = data["trade_sheet_stage"];
      this.dictionary.tradeAuditStatus = data["trade_audit_status"];
      this.dictionary.tradeReceiveStatus = data["trade_receive_status"];
    },
    // 查询订单列表
    async handleQuery() {
      this.dataLoading = true;
      const { success, data } = await reqPagingSettlement(this.queryParam);
      if (success) {
        data.list.forEach((item) => (item.check = false));
        this.tableData = data.list;
        this.dataTotal = data.total;
      }
      this.dataLoading = false;
    },
    // 切换分页条数
    handleSizeChange(value) {
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = value;
      this.handleQuery();
    },
    // 订单列表翻页
    handleCurrentChange(value) {
      this.queryParam.pageNo = value;
      this.handleQuery();
    },
    // 处理数据选中
    handleSelectionChange(selection) {
      this.trade = selection;
    },
    // 处理取消事件
    handleCancel() {
      this.visible = false;
    },
    // 处理确认事件
    handleConfirm() {
      if (!this.trade) {
        this.toast("请您选择要关联的结算订单！", "warning");
        return;
      }

      if (["CS"].indexOf(this.trade.sheetStage) != -1) {
        this.toast("该订单已撤销作废不能关联！", "warning");
        return;
      }

      if (["WJ"].indexOf(this.trade.sheetStage) != -1) {
        this.toast("该订单已订单完结不能关联！", "warning");
        return;
      }

      const message = `确认将已选择的【${this.invoices.length}】张发票，关联【${this.trade.sheetCode}】结算订单吗？`;
      this.$confirm(message, "确认提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const doBindParam = {
            relations: this.invoices.map((item) => {
              return { colInvoiceId: item.id, oriTradeId: this.trade.id };
            }),
          };
          const { success } = await reqDoBindSettlementColInvoice(doBindParam);
          if (success) {
            this.$message.success("关联订单成功！");
            this.visible = false;
            this.$emit("on-bind", this.invoices);
          }
        })
        .catch(() => {});
    },
    // 重置查询条件
    handleReset() {
      this.queryParam = {
        sheetCode: undefined,
        sheetStage: ["BI", "RI"],
        pageNo: 1,
        pageSize: 15,
      };
    },
    // 格式订单时间
    fmtTradeTime(row, column, value) {
      return value == undefined || value == null || value == ""
        ? "--"
        : this.$moment(value).format("YYYY-MM-DD HH:mm:ss");
    },
    // 格式订单金额
    fmtTradeAmount(row, column, value) {
      return fmtCurrency(value, 2, "", ",", ".", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.cont-wrap {
  text-align: left;
  padding: 20px 30px 40px 30px;

  .cont-form {
    padding: 10px;
  }

  .cont-list {
    margin-top: 10px;
  }
}
</style>
