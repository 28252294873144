<template>
  <div class="content-wrap">
    <div class="content-select">
      <el-form
        ref="selectFrom"
        :model="queryParam"
        :inline="true"
        label-width="80px"
        label-position="left"
        style="padding: 5px 20px"
      >
        <el-form-item label="收票时间">
          <el-date-picker
            v-model="queryParam.receiveTime"
            type="daterange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="receiveTimeOptions"
            size="mini"
            style="width: 200px"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发票票号">
          <el-input
            v-model.trim="queryParam.ticketCode"
            placeholder="请输入发票票号"
            maxlength="20"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="票据状态">
          <el-select
            v-model="queryParam.ticketStatus"
            multiple
            placeholder="请选择票据状态"
            collapse-tags
            clearable
          >
            <el-option
              v-for="item in dictionary.colTicketStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="结算单号" v-show="queryExpended">
          <el-select
            :loading="tradeListLoading"
            v-model="queryParam.oriTradeId"
            placeholder="请选择结算订单"
            remote
            clearable
            filterable
            :remote-method="handleTradeQuery"
          >
            <el-option
              v-for="item in tradeList"
              :key="item.id"
              :label="item.sheetCode"
              :value="item.id"
            >
              <div>
                <span style="float: left">{{ item.sheetCode }}</span>
                <span
                  style="
                    float: right;
                    color: #8492a6;
                    font-size: 12px;
                    margin-left: 30px;
                  "
                  ><span>{{
                    getDictLabel(
                      dictionary.tradeReceiveStatus,
                      item.receiveStatus
                    )
                  }}</span>
                  <span style="margin-left: 10px">{{
                    getDictLabel(dictionary.tradeSheetStage, item.sheetStage)
                  }}</span>
                </span>
              </div>
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item style="margin-left: 32px">
          <select-button
            :show-status="false"
            @list-close="handleListClose"
            @list-show="handleListShow"
            @select="handleQuery"
            @reset="handleReset"
          ></select-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-main">
      <div class="content-operate">
        <el-button
          plain
          type="primary"
          @click="handleDoBindTrade"
          v-hasPermi="['coo:trade-receiving:list']"
          >关联订单</el-button
        >
        <el-button
          plain
          type="info"
          @click="handleUnBindTrade"
          :disabled="unBindLoading"
          :loading="unBindLoading"
          v-hasPermi="['coo:trade-receiving:list']"
          >取消关联</el-button
        >
        <el-button
          plain
          type="warning"
          @click="handleReBindTrade"
          v-hasPermi="['coo:trade:check']"
          >重新关联</el-button
        >
        <!-- <el-dropdown @command="handleReceiveInvoice">
          <el-button type="default" v-hasPermi="['coo:trade-receiving:upload']"
            >上传发票<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="localup">本地上传</el-dropdown-item>
            <el-dropdown-item command="scanner">扫描上传</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          plain
          type="danger"
          @click="handleDeleteInvoice"
          v-hasPermi="['coo:trade:revoke']"
          >删除发票</el-button
        > -->
      </div>

      <div class="content-table">
        <el-table
          border
          :data="tableData"
          ref="previewTable"
          v-loading="dataLoading"
          highlight-current-row
          show-summary
          :summary-method="getTableSummary"
          :header-cell-style="handleHeaderCellStyle"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            fixed="left"
            width="55"
            align="center"
          />
          <el-table-column
            type="index"
            fixed="left"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.invoiceType"
            fixed="left"
            label="发票类型"
            min-width="120"
            :formatter="fmtInvoiceType"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.invoiceNo"
            fixed="left"
            label="发票号码"
            width="100"
            header-align="right"
            align="right"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.invoiceCode"
            fixed="left"
            label="发票代码"
            width="130"
            header-align="right"
            align="right"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.eInvoiceNo"
            fixed="left"
            label="数电票号"
            width="180"
            header-align="right"
            align="right"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.ticketStatus"
            label="票据状态"
            width="100"
            header-align="center"
            align="center"
            :formatter="fmtTicketStatus"
          ></el-table-column>
          <el-table-column label="结算单号" width="180">
            <template slot-scope="scope">
              <el-popover
                v-if="scope.row.oriTrade != null"
                placement="top-start"
                width="360"
                trigger="click"
              >
                <el-descriptions title="订单信息" :column="1">
                  <el-descriptions-item label="结算单号">{{
                    scope.row.oriTrade.sheetCode
                  }}</el-descriptions-item>
                  <el-descriptions-item label="结算阶段">{{
                    formatSheetStage(scope.row.oriTrade.sheetStage)
                  }}</el-descriptions-item>
                  <el-descriptions-item label="交票状态">{{
                    formatReceiveStatus(scope.row.oriTrade.receiveStatus)
                  }}</el-descriptions-item>
                  <el-descriptions-item label="交票数量">
                    {{ scope.row.oriTrade.receivedCount }}/{{
                      scope.row.oriTrade.ticketCount
                    }}（已交/总数）
                  </el-descriptions-item>
                </el-descriptions>
                <el-button type="text" slot="reference">{{
                  scope.row.oriTrade.sheetCode
                }}</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="colInvoice.invoiceStatus"
            label="发票状态"
            width="100"
            header-align="center"
            align="center"
            :formatter="fmtInvoiceStatus"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.invoiceTime"
            label="开票日期"
            width="120"
            header-align="center"
            align="center"
            :formatter="fmtInvoiceTime"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.receiveTime"
            label="收票时间"
            width="180"
            :formatter="fmtReceiveTime"
          ></el-table-column>
          <el-table-column
            prop="colRelation.invoicingType"
            label="协同开票"
            width="100"
            header-align="center"
            align="center"
            :formatter="fmtInvoicingType"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.buyerName"
            label="购方名称"
            min-width="160"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.buyerTaxNo"
            label="购方税号"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.sellerName"
            label="销方名称"
            min-width="160"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.sellerTaxNo"
            label="销方税号"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.amount"
            label="未税金额"
            min-width="100"
            header-align="right"
            align="right"
            :formatter="fmtInvoiceAmount"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.taxAmount"
            label="发票税额"
            min-width="100"
            header-align="right"
            align="right"
            :formatter="fmtInvoiceAmount"
          ></el-table-column>
          <el-table-column
            prop="colInvoice.sumAmount"
            label="价税合计"
            min-width="100"
            header-align="right"
            align="right"
            :formatter="fmtInvoiceAmount"
          ></el-table-column>
          <el-table-column
            prop="operate"
            label="操作"
            header-align="center"
            align="center"
            fixed="right"
            width="120"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handlePreviewImage(scope.row)"
                >影像</el-button
              >
              <el-button type="text" @click="handleInvoiceDetail(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryParam.pageNo"
          :page-sizes="pageSizes"
          :page-size="queryParam.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="dataTotal"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 选择订单弹窗 -->
    <select-trade
      ref="selectTradeRef"
      @on-bind="handleInvoiceOnBind"
    ></select-trade>

    <!-- 发票影像预览 -->
    <el-dialog width="80%" title="发票影像" :visible.sync="invoiceImageVisible">
      <col-invoice-image
        v-if="invoiceImageVisible"
        :col-invoice-id="colInvoiceId"
      ></col-invoice-image>
    </el-dialog>

    <!-- 票据详情弹窗 -->
    <invoice-detail
      :loading="detailLoading"
      :show.sync="detailDrawer"
      :invoice="detailInvoice"
    ></invoice-detail>
  </div>
</template>

<script>
import InvoiceDetail from "@/components/invoice/InvoiceDetail";
import ColInvoiceImage from "@/views/invoice-center/component/ColInvoiceImage.vue";
import SelectTrade from "@/views/cooperate/settlement/components/receiving/selectTrade.vue";
import { fmtCurrency } from "@/assets/js/format-util";
import {
  tableStyle,
  pagingMethods,
  dateOption,
  changeMethods,
} from "@/util/mixins";
import { reqPagingSettlement } from "@/service/coo/trade.js";
import {
  reqPagingSettlementColInvoice,
  reqUnBindSettlementColInvoice,
} from "@/service/coo/trade-col-invoice.js";
import { selectInvoice } from "@/service/invoice-center";

export default {
  name: "SettlementReceivingList",
  props: {
    cooRole: {
      type: String,
      default: "SS",
    },
  },
  components: {
    SelectTrade,
    InvoiceDetail,
    ColInvoiceImage,
  },
  mixins: [dateOption, tableStyle, pagingMethods, changeMethods],
  data() {
    return {
      // 数据字典
      dictionary: {
        tradeSheetStage: [], //结算阶段
        tradeReceiveStatus: [], //交票状态
        colTicketStatus: [], //票据状态
      },

      // 订单列表
      tradeList: [],
      tradeListLoading: false,

      // 筛选条件
      queryParam: {
        receiveTime: [],
        ticketStatus: [],
        ticketCode: undefined,
        pageNo: 1,
        pageSize: 15,
      },
      // 展开条件
      queryExpended: false,
      // 时间选项
      receiveTimeOptions: {
        disabledDate: (time) => {
          return time.getTime() >= Date.now() + 0 * 24 * 3600 * 1000;
        },
      },
      // 表格数据
      dataTotal: 0,
      tableData: [],
      dataLoading: false,

      // 选中数据
      invoices: [],
      single: false,
      multiple: false,

      selectTradeVisible: false,

      unBindLoading: false,

      // 发票影像
      colInvoiceId: undefined,
      invoiceImageVisible: false,

      // 发票详情
      detailDrawer: false,
      detailLoading: false,
      detailInvoice: {},
    };
  },
  async created() {
    // console.log("SettlementTradeList:", this.cooRole);
    this.$watch(
      () => this.$route.path,
      async () => {
        if (this.cooRole == "DS") {
          // this.queryBuyerList();
        }
        // this.querySellerList();
        await this.initDict();
        await this.handleQuery();
      }
    );

    if (this.cooRole == "DS") {
      // this.queryBuyerList();
    }
    // this.querySellerList();
    await this.initDict();
    await this.handleQuery();
  },
  methods: {
    // 初始化字典
    async initDict() {
      const { data } = await this.batchGetDicts({
        types: [
          "invoice_status",
          "trade_sheet_stage",
          "trade_receive_status",
          "col_ticket_status",
          "cooperation_invoicing_type",
        ],
      });
      this.dictionary.vatInvoiceStatus = data["invoice_status"];
      this.dictionary.tradeSheetStage = data["trade_sheet_stage"];
      this.dictionary.tradeReceiveStatus = data["trade_receive_status"];
      this.dictionary.colTicketStatus = data["col_ticket_status"];
      this.dictionary.salInvoicingType = data["cooperation_invoicing_type"];
    },
    // 查询订单列表
    async handleTradeQuery(query) {
      if (query !== "") {
        this.tradeListLoading = true;
        const queryParam = {
          sheetCode: query,
          pageNo: 1,
          pageSize: 1,
        };
        const { success, data } = await reqPagingSettlement(queryParam);
        if (success) {
          this.tradeList = data.list;
        }
        this.tradeListLoading = false;
      } else {
        this.tradeList = [];
      }
    },
    // 查询收票列表
    async handleQuery() {
      this.dataLoading = true;
      const { success, data } = await reqPagingSettlementColInvoice(
        this.queryParam
      );
      if (success) {
        data.list.forEach((item) => (item.check = false));
        this.tableData = data.list;
        this.dataTotal = data.total;
      }
      this.dataLoading = false;
    },
    // 切换分页条数
    handleSizeChange(value) {
      this.queryParam.pageNo = 1;
      this.queryParam.pageSize = value;
      this.handleQuery();
    },
    // 列表数据翻页
    handleCurrentChange(value) {
      this.queryParam.pageNo = value;
      this.handleQuery();
    },
    // 处理数据选中
    handleSelectionChange(selection) {
      this.invoices = selection;
      // console.log("selection:", JSON.stringify(selection));
      this.single = this.invoices.length == 1;
      this.multiple = this.invoices.length > 1;
    },
    // 处理关联订单
    handleDoBindTrade() {
      const blTickets = this.invoices.filter(
        (item) => ["BL"].indexOf(item.colInvoice.ticketStatus) != -1
      );
      if (blTickets.length > 0) {
        this.toast(
          "所选数据包含票据状态是【锁定】的发票，请先【取消关联】订单！",
          "warning"
        );
        return;
      }

      const cdTickets = this.invoices.filter(
        (item) => ["CD"].indexOf(item.colInvoice.ticketStatus) != -1
      );
      if (cdTickets.length > 0) {
        this.toast(
          "所选数据包含票据状态是【存档】的发票，请勿【重复关联】订单！",
          "warning"
        );
        return;
      }

      this.$refs.selectTradeRef.showDialog(
        this.invoices.map((item) => {
          return item.colInvoice;
        })
      );
    },
    // 处理关联之后
    handleInvoiceOnBind() {
      this.handleQuery();
    },
    // 处理取消关联
    handleUnBindTrade() {
      const gjTickets = this.invoices.filter(
        (item) => ["GJ"].indexOf(item.colInvoice.ticketStatus) != -1
      );
      if (gjTickets.length > 0) {
        this.toast(
          "所选数据包含票据状态是【归集】的发票，无需执行【取消关联】！",
          "warning"
        );
        return;
      }

      const cdTickets = this.invoices.filter(
        (item) => ["CD"].indexOf(item.colInvoice.ticketStatus) != -1
      );
      if (cdTickets.length > 0) {
        this.toast(
          "所选数据包含票据状态是【存档】的发票，不能执行【取消关联】！",
          "warning"
        );
        return;
      }

      const message = `确认将已选择的【${this.invoices.length}】张发票，取消关联结算订单吗？`;
      this.$confirm(message, "取消关联", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const unBindParam = {
            relationIds: this.invoices.map((item) => {
              return item.colRelation.id;
            }),
          };
          this.unBindLoading = true;
          const { success } = await reqUnBindSettlementColInvoice(unBindParam);
          this.unBindLoading = false;
          if (success) {
            this.$message.success("取消关联成功！");
            this.handleQuery();
          }
        })
        .catch(() => {});
    },
    // 处理重新关联
    handleReBindTrade() {
      this.toast("功能正在开发，敬请期待！", "warning");
    },
    // 浏览发票影像
    handlePreviewImage(row) {
      this.colInvoiceId = row.colInvoice.id;
      this.invoiceImageVisible = true;
    },
    // 浏览发票详情
    handleInvoiceDetail(row) {
      this.detailDrawer = !this.detailDrawer;
      let invoice = row.colInvoice;
      this.detailLoading = true;
      selectInvoice(invoice.id).then((res) => {
        if (res.success) {
          this.detailLoading = false;
          let details = res.data.items;
          if (details) {
            details.forEach(
              (item) => (item.taxRate = Number(item.taxRate) * 100 + "%")
            );
            // 统计信息
            let total = {
              merchandiseName: "合计",
              amount: invoice.amount ? invoice.amount : 0,
              taxAmount: invoice.taxAmount ? invoice.taxAmount : 0,
            };
            details.push(total);
            let sum = Number(invoice.sumAmount).toFixed(2);
            let sumBig = this.handleSmallToCapitalize(sum);
            let priceTax = {
              merchandiseName: "价税合计（大写）",
              specification: sumBig + "  " + "（小写）￥" + sum,
            };
            details.push(priceTax);
          }
          let invoiceData = res.data;
          invoiceData.items = details;
          this.detailInvoice = invoiceData;
        }
      });
    },
    // 处理删除发票
    handleDeleteInvoice() {},
    // 处理收票命令
    handleReceiveInvoice(cmd, row) {
      // console.log("settlement:", settlement);
      if (typeof row == "undefined") {
        row = this.getSelectedSettlement();
      }

      let path = `/coo/${
        this.cooRole == "DS" ? "buy" : "sal"
      }/trade/receiving/${cmd}`;

      this.$router.push({
        path: path,
        query: {
          sceneName: "JS",
          settlementId: row.id,
          sceneRole: this.cooRole,
        },
      });
    },
    // 收起筛选列表
    handleListClose() {
      this.queryExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.queryExpended = true;
    },
    // 重置查询条件
    handleReset() {
      this.queryParam = {
        receiveTime: [],
        ticketStatus: [],
        ticketCode: undefined,
        pageNo: 1,
        pageSize: 15,
      };
    },
    // 格式开票时间
    fmtInvoiceTime(row, column, value) {
      if (value != undefined) {
        return this.$moment(value).format("YYYY-MM-DD");
      }
      return "--";
    },
    // 格式收票时间
    fmtReceiveTime(row, column, value) {
      if (value != undefined) {
        return this.$moment(value).format("YYYY-MM-DD HH:mm:ss");
      }
      return "--";
    },
    // 格式发票类型
    fmtInvoiceType(row, column, value) {
      return this.handleValueToLabel("InvoiceType", value);
    },
    // 格式票据状态
    fmtTicketStatus(row, column, value) {
      return this.getDictLabel(this.dictionary.colTicketStatus, value);
    },
    // 格式结算阶段
    formatSheetStage(value) {
      return this.getDictLabel(this.dictionary.tradeSheetStage, value);
    },
    // 格式交票状态
    formatReceiveStatus(value) {
      return this.getDictLabel(this.dictionary.tradeReceiveStatus, value);
    },
    // 格式发票状态
    fmtInvoiceStatus(row, column, value) {
      return this.getDictLabel(this.dictionary.vatInvoiceStatus, value);
    },
    // 协同开票方式
    fmtInvoicingType(row, column, value) {
      return this.getDictLabel(this.dictionary.salInvoicingType, value);
    },
    // 格式发票金额
    fmtInvoiceAmount(row, column, value) {
      return fmtCurrency(value, 2, "", ",", ".", "");
    },
    // 计算收票汇总
    getTableSummary(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 1) {
          sums[index] = "汇总";
        } else {
          let idx = [
            "colInvoice.amount",
            "colInvoice.taxAmount",
            "colInvoice.sumAmount",
          ].indexOf(`${column.property}`);
          if (idx == -1) {
            sums[index] = "";
          } else {
            const values = data.map((item) => {
              if (idx == 0) {
                return Number(item.colInvoice.amount);
              } else if (idx == 1) {
                return Number(item.colInvoice.taxAmount);
              } else {
                return Number(item.colInvoice.sumAmount);
              }
            });

            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + value;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] = Number(sums[index]).toFixed(2) + " 元";
            } else {
              sums[index] = "";
            }
          }
        }
      });

      return sums;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/select.scss";

.select-header {
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      margin-right: 10px;
      color: #666666;
      font-size: 14px;
      font-weight: 400;
    }

    input {
      border: none;
    }

    ::v-deep .el-input__inner {
      border: none;
      border-radius: 0;
    }

    ::v-deep .el-input__icon {
      color: #1890ff;
    }

    span {
      color: #666666;
      font-weight: 400;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;
    }

    span:hover {
      cursor: pointer;
    }
  }

  .select-title {
    height: auto;
  }

  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 186px);

  .content-operate {
    display: flex;
    justify-content: flex-end;

    .el-button {
      padding: 0px 10px;
      margin-bottom: 12px;
      margin-left: 10px;
    }
  }

  .content-table {
    .el-button {
      padding: 0;
    }

    .el-dropdown {
      padding: 0 10px 0 10px;
    }

    .el-link {
      color: #3d94ff;
      padding-left: 5px;
      padding-bottom: 4px;
    }
  }
}

.pdt-switch {
  display: inline-flex;
  vertical-align: middle;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  width: 160px;
  height: 28px;
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  border-radius: 14px;
  border: 1px dashed #cccccc;
  background-color: #eeeeee;

  i {
    font-style: normal;
    padding: 0 10px;
    font-size: 12px;
    color: #666666;
  }

  .is-switch {
    position: absolute;
    display: inline-block;
    height: 28px;
    line-height: 28px;
    width: 60px;
    border: 1px dashed #cccccc;
    color: #ffffff;
    border-radius: 14px;
    background-color: #1890ff;
  }

  .is-buyer {
    margin-left: 80px;
  }
}

.tips-body {
  text-align: left;
  margin: -18px 24px 12px 20px;

  span {
    width: 256px;
    height: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
  }
}

.dialog-delete {
  padding: 24px 24px 32px;
  text-align: left;

  .delete-top {
    padding-bottom: 12px;

    .label-span {
      margin-bottom: 12px;
    }
  }

  .delete-line {
    margin: 0 -24px;
    border-bottom: 1px solid #e9e9e9;
  }

  .delete-bottom {
    padding-top: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding: 0 24px 24px;
}

.el-form--inline .el-form-item {
  margin: 10px 10px 10px 0;
}
.select-button {
  min-width: 200px;
  margin-left: 30px;
}

::v-deep .el-range-editor--mini .el-range-separator {
  line-height: inherit;
}
</style>
